'use client';
import React from 'react';
import Image from 'next/image';

import { LinkButton } from '../ui/link-button';

import { PlanCardProps } from './plan-card.interfaces';

import {
  BulletCheckIcon,
  EstudiosExclusivos,
  MeditacaoGuiada,
  PlanoAlimentarIcon,
  PsicologiaVivaIcon,
  SmartCoachIcon,
  TreinosOnline,
  SmartFitIcon,
  Location,
} from '@/assets/icons';

export const PlanCard: React.FC<PlanCardProps> = ({ description, topics, label, logos, price, title, isMX }) => {
  const cardRadius = label ? 'bg-[#26d07c40]' : 'bg-white';

  const cardWidth = isMX ? 'min-h-[650px]' : 'min-h-[640px]';

  const descriptionWidth = isMX ? '' : 'md:min-h-[100px] lg:min-h-[115px]';

  return (
    <>
      <div
        className={`flex ${cardWidth} flex-col justify-between rounded-2xl ${cardRadius} p-8 text-4xl hover:border hover:border-neutral-90 hover:bg-white`}
      >
        <div className="item-start mb-4 flex-col space-y-3">
          <div className="space-y-3">
            <div className={`flex flex-col ${descriptionWidth}`}>
              <div className="flex w-full items-start justify-between">
                <h3 className="text-3xl font-semibold">{title}</h3>
                {label && (
                  <div className="flex">
                    <div className="z-30 flex items-center justify-center rounded-2xl bg-primary-40 px-2 text-sm">
                      {isMX ? 'Más popular' : 'Mais popular'}
                    </div>
                  </div>
                )}
              </div>
              <p className="text-sm">{description}</p>
            </div>
            <p className="text-sm font-medium">{isMX ? 'Valor para los colaboradores' : 'Valor para colaboradores:'}</p>
            <div className="flex items-end text-sm">
              {isMX ? '$' : 'R$'}
              <p className="text-3xl md:text-3xl">{price}</p>
              {isMX ? 'por mes*' : '/mês*'}
            </div>
            <LinkButton
              href={isMX ? 'https://comunicacion.totalpass.com.mx/registro-empresas' : '/br/planos/'}
              data-test="button-card"
              className="mt-4 w-full self-end text-sm"
              variant="primary"
              target={isMX ? '_blank' : '_self'}
            >
              {isMX ? 'Conocer más' : 'Saiba mais'}
            </LinkButton>
            <p className="text-sm font-medium">{isMX ? 'Obtenga acceso a:' : 'Tenha acesso a: '}</p>
            {topics &&
              topics.map(({ text, topicIcon }, index) => {
                let image;

                switch (topicIcon) {
                  case 'Location':
                    image = Location;
                    break;
                  case 'Smart_fit':
                    image = SmartFitIcon;
                    break;
                  case 'Psicologia_viva':
                    image = PsicologiaVivaIcon;
                    break;
                  case 'Smart_coach':
                    image = SmartCoachIcon;
                    break;
                  case 'Treino_on_line':
                    image = TreinosOnline;
                    break;
                  case 'Meditacao_guiada':
                    image = MeditacaoGuiada;
                    break;
                  case 'Plano_alimentar':
                    image = PlanoAlimentarIcon;
                    break;
                  case 'Estudios_exclusivos':
                    image = EstudiosExclusivos;
                    break;
                  default:
                    image = BulletCheckIcon;
                    break;
                }

                return (
                  <div key={index} className="flex gap-2">
                    <Image
                      className="mt-[2px] self-baseline"
                      src={image}
                      alt="Ícone de um check"
                      width={14}
                      height={14}
                    />
                    <p className="text-xs">{text}</p>
                  </div>
                );
              })}
          </div>
        </div>
        <div>
          <hr className="my-3 border-t-2 border-[#BABABA] opacity-100" />
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-3 pt-3">
              <div className="flex w-full justify-between self-center px-6 py-2">
                {logos.map((logo, index) => {
                  const image = logo.image.url;
                  return (
                    <div key={index} className="flex h-16 w-16 flex-col items-center gap-2 md:h-16 md:w-16">
                      <Image
                        src={image}
                        alt={logo.alt}
                        title={logo.seoTitle}
                        width={50}
                        height={50}
                        loading="lazy"
                        unoptimized
                        className="rounded-full"
                      />
                      <p className="text-center text-xs">{logo.seoTitle}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PlanCard.displayName = 'SwiperSlide';
