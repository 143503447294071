export { default as Alert } from './alert.svg';
export { default as BlackLeftArrow } from './black-left-arrow.svg';
export { default as BlackRightArrow } from './black-right-arrow.svg';
export { default as BulletCheckIcon } from './bullet-check-icon.svg';
export { default as Cookies } from './cookies.png';
export { default as EstudiosExclusivos } from './estudios-exclusivos.svg';
export { default as GreenBulletRoundCheck } from './green-bullet-round-check.svg';
export { default as GrennInclinedArrow } from './green-inclined-arrow.svg';
export { default as GreenRightArrow } from './green-right-arrow.svg';
export { default as Location } from './location.svg';
export { default as MeditacaoGuiada } from './meditacao-guiada.svg';
export { default as PinkBulletRoundCheck } from './pink-bullet-round-check.svg';
export { default as PlanoAlimentarIcon } from './plano-alimentar-icon.svg';
export { default as PsicologiaVivaIcon } from './psicologia-viva-icon.svg';
export { default as PurpleBulletRoundCheck } from './purple-bullet-round-check.svg';
export { default as SmartCoachIcon } from './smart-coach-icon.svg';
export { default as SmartFitIcon } from './smart-fit-icon.svg';
export { default as TreinosOnline } from './treinos-on-lines.svg';
export { default as WhiteBulletCheckIcon } from './white-bullet-check-icon.svg';
export { default as WhiteRightArrow } from './white-right-arrow.svg';
